import $ from 'jquery';
import MagicGrid from "magic-grid";
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

export default {

	init() {

		var container = document.querySelector('.Gallery');

		if( container ) {

			function galleryMagicGrid( container ) {

				var imagesLength = container.querySelectorAll('.content__box').length;

				let magicGrid = new MagicGrid({
			        container: '.gallery__content--size',
			        maxColumns: 3,
			        items: imagesLength,
			        gutter: 0,
			        animate: true,
			        center: false
			    });
			    magicGrid.listen();

			}

			function galleryPhotoSwipeit( container ) {

				var images = container.querySelectorAll('.content__box');

				// -- INIT -- //

					var pswp = document.querySelector('.pswp'); // photoSwipe mask
					var items; // photoSwipe images
					var options = Array(); // photoSwipe options
						options.zoomEl = true;
						options.shareEl = false;

					var photoSwipe; // photoSwipe object

					function Init(index) {

						items = Array();

						options.index = parseInt(index, 10);

						var item; 
						var image;
						var image_url;
						var image_dimention;
						var image_width;
						var image_height;

						images.forEach( function( image , index2 ) {

							image_url = image.getAttribute('data-url');
							image_dimention = image.getAttribute('data-size').split('x');
							image_width = image_dimention[0];
							image_height = image_dimention[1];

							item = {

								src: image_url,
			                    w: image_width,
			                    h: image_height

							};

							items.push( item );

						});

						photoSwipe = new PhotoSwipe( pswp, PhotoSwipeUI_Default, items, options);

					}

					Init();

				// -- END -- //


				// -- OPEN -- //

					images.forEach( function( image, index ) {

						image.addEventListener('click' , function() {

							Init( index );
							photoSwipe.init();

						});

					});

				// -- END -- ///

			}

			galleryMagicGrid( container );
			galleryPhotoSwipeit( container );

		}

	},
	finalize() {

	}

}