export default {

	init() {

		var footer = document.querySelector('footer');

		if( footer.getAttribute('data-popup') ) {

			function SetPopupCookie(cname, cvalue, exdays) {
			    var d = new Date();
			    d.setTime(d.getTime() + (exdays*24*60*60*1000));
			    var expires = "expires="+ d.toUTCString();
			    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
			}

			function OnPopupContainerClick() {

				var popupContainer = document.querySelector('.popup');
				var popupClose = popupContainer.querySelector('.popup__close');

				popupClose.addEventListener('click', function() {

					SetPopupCookie(popupName , popupValue , popupLive);
					popupContainer.classList.remove('active');
					setTimeout(
						function() {

							popupContainer.remove();

						},
						1000
					);
					
				});

			}

			function ShowPopupDiv() {
				var url = document.querySelector('footer').getAttribute('data-endpoint');

				var req = new XMLHttpRequest();

				req.onreadystatechange = function(){

					if ( req.readyState==4 && req.status==200 ) {
						
						document.querySelector('body').insertAdjacentHTML('beforeend', req.responseText);				

						setTimeout(
							function(){ 
								document.querySelector('.popup').classList.add('active');
							}, 
							1000
						);

						
						OnPopupContainerClick();

					}

				}

				req.open( 'POST', url, true );
				req.setRequestHeader( 'Content-type', 'application/x-www-form-urlencoded' );
				req.send( 'action=PopupInfo' );
				
			}	

			function CheckCookiePopup(cname) {

			    var name = cname + "=";
			    var decodedCookie = decodeURIComponent(document.cookie);
			    var ca = decodedCookie.split(';');
			    for(var i = 0; i <ca.length; i++) {
			        var c = ca[i];
			        while (c.charAt(0) == ' ') {
			            c = c.substring(1);
			        }
			        if (c.indexOf(name) == 0) {
			            return c.substring(name.length, c.length);
			        }
			    }
			    return "";

			}

			var popupName = 'popup-info';
			var popupValue = '1';
			var popupLive = 0; //in day

			var myPopup = CheckCookiePopup(popupName);

			if( myPopup != 1 ) {

				ShowPopupDiv();

			};

		}

	},
	finalize() {

	}

}