import ServiceSlick from '../../Sections/ServiceSlick/ServiceSlick.js';

export default {

	init() {

		ServiceSlick.init();

	},
	finalize() {

		// JavaScript to be fired on the home page, after the init JS

	},

};
