import "intersection-observer";
import scrollama from "scrollama";

export default {

	init() {

		var container = document.querySelector('.ServicesList');

		if( container ) {

			function calcPosition() {

				var headHeight = document.querySelector('.PageHead').offsetHeight;
				var headerHeight = document.querySelector('header').offsetHeight;

				var pageHeight = window.innerHeight;

				return ( headHeight + headerHeight ) / pageHeight;

			}

			var servicesArticle = container.querySelectorAll('.list__single');
			var servicesImage = container.querySelectorAll('.images__single--service');
			var servicesDefault = container.querySelector('.image__single--default');

			const scroller = scrollama();

			scroller
				.setup({
					step: ".list__single",
					offset: calcPosition(),
				})
			  	.onStepEnter(response => {
			    	// { element, index, direction }

			    	servicesDefault.classList.add('active');
			    	servicesArticle[response.index].classList.add('active');
			    	servicesImage[response.index].classList.add('active');

			  	})
			  	.onStepExit(response => {
			    	// { element, index, direction }

			    	servicesDefault.classList.remove('active');
			    	servicesArticle[response.index].classList.remove('active');
			    	servicesImage[response.index].classList.remove('active');

			  	});

			window.addEventListener("resize", scroller.resize);


		}

	},
	finalize() {

	}

}