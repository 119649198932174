// Defaults
import './style.scss';
import Router from './scripts/Router';
import LazyLoad from './scripts/lazyLoad';
import Subpage from '../src/Layout/Subpage';

// Pages
import homepage from '../src/Pages/Homepage/Homepage';
import servicec from '../src/Pages/ServiceC/ServiceC';
import serviced from '../src/Pages/ServiceD/ServiceD';
import gallery from '../src/Pages/Gallery/Gallery';
import contact from '../src/Pages/Contact/Contact';

LazyLoad.init();
Subpage.init();

const routes = new Router({
	homepage,
	servicec,
	serviced,
	gallery,
	contact,
});

document.addEventListener("DOMContentLoaded", function() {
	routes.loadEvents();
});