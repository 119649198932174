import $ from 'jquery';

export default {

	init() {

		var container = document.querySelector('.PageHead--services');

		if( container ) {

			var links = container.querySelectorAll('.pageHead__links .links__single');

			links.forEach( function( link,index ) {

				link.addEventListener('click', function(e) {

					e.preventDefault();

					if( document.getElementById(link.getAttribute('href').split("#")[1] ) ) {

						$('html ,body').animate({'scrollTop': document.getElementById(link.getAttribute('href').split("#")[1] ).offsetTop  }, 'smooth');

					}

				});

			});

		}

	},
	finalize() {

	}

}