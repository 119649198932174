export default {
    init() {
        let t = this;
        let node = document.querySelector('.CookiePreferences');
        let cookieName = 'CookieConsent';
        let cookieValue = t.CheckCookie( cookieName );
        let cookieLive = 60;

        if( !node ) {
            return;
        }
        if( cookieValue == '' ) {
            setTimeout(() => {
                node.classList.add('active');
            }, 200);
        } else {
            gtag( "consent", "update", JSON.parse( cookieValue ) );
        }

        node.querySelector('.footer__save').addEventListener('click', function() {

            let arr = {};
            node.querySelectorAll('input[type="checkbox"]').forEach( function( term, index ) {
                let value = 'denied';
                if( term.checked ) { value = 'granted'; }
                term.getAttribute('name').split(',').forEach( function( name, index ) {
                    arr[ name.trim() ] = value;
                });
            });
            gtag( "consent", "update", arr );
            t.SetCookie('CookieConsent', JSON.stringify( arr ), cookieLive );
            node.classList.remove('active');
            
        });

        node.querySelector('.footer__close').addEventListener('click', () => {
            node.classList.remove('active');
        });
        document.querySelectorAll('[href="#cookie_preferences"]').forEach( ( link, index ) => {
            link.addEventListener('click', ( event ) => {
                event.preventDefault();
                node.classList.add('active');
            });
        });

    },
    finalize() {

    },
    CheckCookie(cname) {

        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";

    },
    SetCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
}