import $ from 'jquery';
import ServicesList from '../../Sections/ServicesList/ServicesList.js';

export default {

	init() {


		var container = document.querySelector('main.ServiceD');

		if( container ) {

			ServicesList.init();
			
			function headerScroll() {

				var header = document.querySelector('header');
				var items = header.querySelectorAll('.menu__child li a');

				items.forEach( function( item, index ) {

					item.addEventListener('click', function(e) {

						e.preventDefault();

						console.log(item.getAttribute('href').split('#')[0]);
						console.log(document.getElementById( item.getAttribute('href').split('#')[1] ) );

						if( 
							!item.getAttribute('href').split('#')[0] && 
							document.getElementById( item.getAttribute('href').split('#')[1] ) 
						) {

							$('html ,body').animate({'scrollTop': document.getElementById( item.getAttribute('href').split('#')[1] ).offsetTop  }, 'smooth');

						}

					});

				});

			}

			headerScroll();

		}

	},
	finalize() {

	}

}