// import Cookie from '../Components/Cookie/Cookie.js';
import CookiePreferences from '../Components/CookiePreferences/CookiePreferences.js';
import Popup from '../Components/Popup/Popup.js';

export default {

	init() {

		// Cookie.init();
		CookiePreferences.init();
		Popup.init();

		var container = document.querySelector('header');

		if( container ) {

			function mobileMenu() {

				var hambuger = container.querySelector('.contact__hamburger');

				var menu = document.querySelector('.mobileMenu');
				var menuContent = menu.querySelector('.mobileMenu__content');

				hambuger.addEventListener('click', function(e) {

					e.stopPropagation();

					if( menu.classList.contains('active') ) {

						menu.classList.remove('active');
						hambuger.classList.remove('is-active');

					} else {

						menu.classList.add('active');
						hambuger.classList.add('is-active');

					}

				});

				menu.addEventListener('click', function(e) {

					e.stopPropagation();

					menu.classList.remove('active');
					hambuger.classList.remove('is-active');

				});

				document.addEventListener('click',function() {

					menu.classList.remove('active');
					hambuger.classList.remove('is-active');

				});

				menuContent.addEventListener('click', function(e) {

					e.stopPropagation();

				});

			}

			mobileMenu();

		}

	},
	finalize(){

	}

}