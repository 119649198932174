import $ from 'jquery';
import slick from 'slick-carousel';

export default {

	init() {

		var container = document.querySelector('.ServiceSlick');

		if( container ) {

			var carousel = container.querySelector('.services__slick');
			var buttonPrev = container.querySelector('.services__button--prev');
			var buttonNext = container.querySelector('.services__button--next');

			$(carousel).slick({
				dots: false,
				arrows: false,
				slidesToShow: 4,
				slidesToScroll: 1,
				infinite: true,
				autoplay: true,
				autoplaySpeed: 1500,
				responsive: [
					{
						breakpoint: 1241,
						settings : {
							slidesToShow: 3,
						}
					},
					{
						breakpoint: 741,
						settings: {
							slidesToShow: 2,
						}						
					},
					{
						breakpoint: 361,
						settings: {
							slidesToShow: 1,
						}
					}

				]
			});

			buttonPrev.addEventListener('click', function() {
				$(carousel).slick('slickPrev');
			});

			buttonNext.addEventListener('click', function() {
				$(carousel).slick('slickNext');
			});				

		}

	},
	finalize() {

	}

}