import PageHead from '../../Sections/PageHead/PageHead.js';
import $ from 'jquery';

export default {

	init() {

		var container = document.querySelector('main.ServiceC');

		if( container ) {

			PageHead.init();

			function headerScroll() {

				var header = document.querySelector('header');
				var items = header.querySelectorAll('.menu__child li a');

				items.forEach( function( item, index ) {

					item.addEventListener('click', function(e) {

						e.preventDefault();

						if( 
							!item.getAttribute('href').split('#')[0] && 
							document.getElementById( item.getAttribute('href').split('#')[1] ) 
						) {

							$('html ,body').animate({'scrollTop': document.getElementById( item.getAttribute('href').split('#')[1] ).offsetTop  }, 'smooth');

						}

					});

				});

			}

			headerScroll();

		}

	},
	finalize() {

	}

}