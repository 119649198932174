import ContactForm from '../../Components/ContactForm/ContactForm.js';

export default {

	init() {

		ContactForm.init();

	},
	finalize() {

	}

}