export default {

	init() {

		var container = document.querySelector('form.ContactForm');

		if( container ) {

			var form = container;
			var button = container.querySelector('.contactForm__submit');
			var fields = container.querySelectorAll('.formField');

			var requestData = [];
			var formSubmit = false;
			var request = new XMLHttpRequest();
			var response;

			var CallbackEmpty = form.getAttribute('data-empty');
			var CallbackError = form.getAttribute('data-error');
			var CallbackSuccess = form.getAttribute('data-success');

			function contactFormCallback( message, status = 'error' ) {

				container.querySelectorAll('.contactForm__callback').forEach( function( callback, index ) {

					callback.remove();

				});

				if( message ) {

					button.insertAdjacentHTML('beforebegin','<p class="contactForm__callback '+status+' ">'+message+'</p>');

				}

			}

			button.addEventListener('click', function(e) {

				e.preventDefault();

				if( !button.classList.contains('disable') && !formSubmit ) {

					button.classList.add('disable');

					fields.forEach( function( input, index ) {

						if( input.type == 'checkbox' ) {

							if( input.checked == false ) {
								input.classList.add('empty');
							} else {
								input.classList.remove('empty');
							}

						} else {

							if( !input.value ) {
								input.classList.add('empty');
							} else {
								input.classList.remove('empty');
							}
						}

					});

					if( container.querySelectorAll('.formField.empty').length == 0 ) {

						contactFormCallback( '' );

						// -- requestData -- //

							requestData = [];

							requestData.push( 'action=sendMail' );
							requestData.push( 'security='+container.querySelector('#security').value );

							fields.forEach( function( input, index ) {

								if( input.type != 'checkbox' ) {

									requestData.push( input.getAttribute('name') +'='+input.value );

								}

							});

							requestData = requestData.join('&');

						// -- END -- //

						request.onreadystatechange = function() {

							if( request.responseText ) {

								response = JSON.parse(request.responseText);

								if( response.status == 'success' ) {

									contactFormCallback( CallbackSuccess, 'success' );
									formSubmit = true;

								} else if( response.status == 'error' ) {

									contactFormCallback( CallbackError );
									button.classList.remove('disable');

								}

							} 

						}

						// Set up our request
						request.open('POST', document.querySelector('footer').getAttribute('data-endpoint') );
						// Add the required HTTP header for form data POST requests
						request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
						// Finally, send our data.
						request.send( requestData );

					} else {

						contactFormCallback( CallbackEmpty );
						button.classList.remove('disable');

					}

				}

			});

		}

	},
	finalize() {
		
	}

}